import { useEffect } from 'react';

const IubendaScript = () => {
  useEffect(() => {
    // Aggiungi gli script di Iubenda al documento
    const scripts = [
      {
        id: 'iubenda-configuration',
        type: 'text/javascript',
        content: `
          var _iub = _iub || [];
          _iub.csConfiguration = {
            "siteId": 3866068,
            "cookiePolicyId": 74582188,
            "lang": "en-GB",
            "storage": {
              "useSiteId": true
            },
            "enableCcpa": true,
            "ccpaAcknowledgeOnDisplay": true
          };
        `
      },
      {
        id: 'iubenda-autoblocking',
        type: 'text/javascript',
        src: 'https://cs.iubenda.com/autoblocking/3866068.js'
      },
      {
        id: 'iubenda-gpp-stub',
        type: 'text/javascript',
        src: '//cdn.iubenda.com/cs/gpp/stub.js'
      },
      {
        id: 'iubenda-cs',
        type: 'text/javascript',
        src: '//cdn.iubenda.com/cs/iubenda_cs.js',
        charset: 'UTF-8',
        async: true
      }
    ];

    scripts.forEach(scriptConfig => {
      const script = document.createElement('script');
      script.id = scriptConfig.id;
      script.type = scriptConfig.type;
      
      if (scriptConfig.content) {
        script.textContent = scriptConfig.content;
      }
      
      if (scriptConfig.src) {
        script.src = scriptConfig.src;
      }
      
      if (scriptConfig.charset) {
        script.charset = scriptConfig.charset;
      }
      
      if (scriptConfig.async) {
        script.async = true;
      }

      document.head.appendChild(script);
    });

    // Cleanup function
    return () => {
      scripts.forEach(scriptConfig => {
        const script = document.getElementById(scriptConfig.id);
        if (script) {
          script.remove();
        }
      });
    };
  }, []);

  return null;
};

export default IubendaScript;