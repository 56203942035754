import React, { useState, useEffect } from "react";
import translations from './translations';
import {
  Home,
  Info,
  Target,
  Send,
  BarChart2,
  Globe,
  CheckCircle,
  Mail,
  Users,
  Briefcase,
  Award,
  Phone
} from "lucide-react";

const EcoDreamWebsite = () => {
  const [language, setLanguage] = useState("en");

  const currentLang = translations[language];
  
  // Effetto per aggiornare l'attributo lang dell'elemento <html>
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  return (
    <div className="bg-gray-50 text-gray-800">
      {/* Navigation */}
      <nav className="fixed top-0 left-0 w-full bg-[#E4E9EB] shadow-md z-50">
        <div className="container mx-auto flex justify-between items-center p-4">
          <div>
            <img
              src="/Logo.png"
              alt="EcoDream Logo"
              className="h-12" // Modifica le dimensioni in base al layout
            />
          </div>
          <div className="flex items-center space-x-8">
            {[
              { href: "#home", icon: Home, label: currentLang.home },
              { href: "#about", icon: Info, label: currentLang.about },
              {
                href: "#services",
                icon: BarChart2,
                label: currentLang.services,
              },
              { href: "#mission", icon: Target, label: currentLang.mission },
              { href: "#contact", icon: Send, label: currentLang.contact },
            ].map(({ href, icon: Icon, label }) => (
              <a
                key={href}
                href={href}
                className="group flex items-center text-gray-700 hover:text-green-600 transition-colors duration-300"
              >
                <Icon
                  className="mr-2 text-gray-500 group-hover:text-green-600 transition-colors"
                  size={20}
                />
                <span className="text-sm font-medium">{label}</span>
              </a>
            ))}

            <div className="flex space-x-2 pl-4 border-l border-gray-200">
              {[
                { code: "en", label: "ENG" },
                { code: "cz", label: "CES" },
              ].map(({ code, label }) => (
                <button
                  key={code}
                  onClick={() => setLanguage(code)}
                  className={`
                  px-3 py-1 rounded-full text-sm font-semibold transition-all duration-300
                  ${
                    language === code
                      ? "bg-green-500 text-white"
                      : "bg-gray-100 text-gray-700 hover:bg-green-100"
                  }
                `}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </nav>

      {/* Home Section */}
      <section
        id="home"
        className="min-h-screen pt-20 flex items-center relative bg-gradient-to-br from-white to-green-50"
        style={{
          backgroundImage: 'url("/Background.jpeg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Overlay bianco semi-trasparente */}
        <div className="absolute inset-0 bg-white opacity-70 backdrop-blur-sm"></div>

        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold text-green-800 mb-6">
            {currentLang.homeTitle}
          </h1>
          <p className="text-xl max-w-2xl mx-auto text-gray-700">
            {currentLang.homeSubtitle}
          </p>
          <a
            href="#contact"
            className="mt-8 inline-block bg-green-600 text-white px-8 py-3 rounded-full hover:bg-green-700 transition duration-300"
          >
            {currentLang.contactUs}
          </a>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 md:pr-12 mb-8 md:mb-0">
            <h2 className="text-3xl md:text-4xl font-bold text-green-800 mb-6">
              {currentLang.whoWeAre}
            </h2>
            <p className="text-gray-700 leading-relaxed">
              {currentLang.whoWeAreDescription}
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-green-100 p-6 rounded-lg text-center shadow-md transform transition hover:scale-105">
                <Globe className="mx-auto mb-4 text-green-700" size={50} />
                <h3 className="font-bold text-green-900">
                  {currentLang.experience}
                </h3>
              </div>
              <div className="bg-green-100 p-6 rounded-lg text-center shadow-md transform transition hover:scale-105">
                <CheckCircle
                  className="mx-auto mb-4 text-green-700"
                  size={50}
                />
                <h3 className="font-bold text-green-900">
                  {currentLang.customSolutions}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

     {/* Nuova Sezione Servizi Estesa */}
    <section id="services" className="py-20 bg-gray-50">
      <div className="container mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-green-800">
            {currentLang.ourServices}
          </h2>
          <p className="text-xl text-gray-600 mt-4">
          {currentLang.serviceDesc}
          </p>
        </div>
        {/* Aggiunta di `justify-center` per centrare le card */}
        <div className="grid md:grid-cols-3 gap-8 justify-center">
          {/* Servizi esistenti */}
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
            <BarChart2 className="mx-auto mb-4 text-green-700" size={50} />
            <h3 className="font-bold text-xl mb-4">
              {currentLang.financialConsulting}
            </h3>
            <p className="text-gray-600">
              {currentLang.financialConsultingDesc}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
            <Users className="mx-auto mb-4 text-green-700" size={50} />
            <h3 className="font-bold text-xl mb-4">
              {currentLang.expertTeam}
            </h3>
            <p className="text-gray-600">{currentLang.expertTeamDesc}</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
            <Briefcase className="mx-auto mb-4 text-green-700" size={50} />
            <h3 className="font-bold text-xl mb-4">
              {currentLang.marketAnalysis}
            </h3>
            <p className="text-gray-600">{currentLang.marketAnalysisDesc}</p>
          </div>
        </div>
      </div>
    </section>

      {/* Nuova Sezione Valori */}
      <section className="py-20 bg-green-50">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-green-800 mb-12">
            {currentLang.keyValues}
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Award,
                title: currentLang.innovation,
                desc: "Constantly seeking new approaches and solutions.",
              },
              {
                icon: Globe,
                title: currentLang.adaptability,
                desc: "Flexible strategies for changing market conditions.",
              },
              {
                icon: CheckCircle,
                title: currentLang.clientFocus,
                desc: "Dedicated to our clients' success and growth.",
              },
            ].map((value, index) => (
              <div key={index} className="bg-white p-8 rounded-lg shadow-md">
                <value.icon className="mx-auto mb-6 text-green-700" size={60} />
                <h3 className="text-2xl font-bold mb-4 text-green-900">
                  {value.title}
                </h3>
                <p className="text-gray-600">{value.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section id="mission" className="py-20 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold text-green-800 mb-8">
            {currentLang.ourMission}
          </h2>
          <p className="text-xl max-w-3xl mx-auto text-gray-700 leading-relaxed">
            {currentLang.ourMissionDesc}
          </p>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-16 bg-green-100">
        <div className="container mx-auto px-4">
          <div className="max-w-xl mx-auto bg-white rounded-xl shadow-lg p-8 text-center">
            <h2 className="text-3xl font-bold text-green-900 mb-8">
              {currentLang.contactSection}
            </h2>
            <div className="space-y-6">
              <div className="flex items-center justify-center space-x-4">
                <Mail className="text-green-600" size={32} />
                <a
                  href="mailto:info@eco-dream.eu"
                  className="text-lg text-green-800 hover:text-green-600 transition-colors"
                >
                  info@eco-dream.eu
                </a>
              </div>
              <div className="flex items-center justify-center space-x-4">
                <Phone className="text-green-600" size={32} />
                <a
                  href="tel:+420601524815"
                  className="text-lg text-green-800 hover:text-green-600 transition-colors"
                >
                  +420 601 524 815
                </a>
              </div>
              <div className="flex items-center justify-center space-x-4">
                <Home className="text-green-600" size={32} />
                <p className="text-gray-700">{currentLang.address}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
<footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white py-12">
  <div className="container mx-auto px-4">
    <div className="flex flex-col items-center text-center">
      {/* Logo Section */}
      <div className="mb-8">
        <img src="/Logo.png" alt="EcoDream Logo" className="h-12" />
      </div>

      {/* Contact Information */}
      <div className="mb-8">
        <p className="text-gray-300 mb-2">{currentLang.address}</p>
        <div className="space-y-2">
          <a
            href="mailto:info@eco-dream.eu"
            className="text-green-300 hover:text-green-200 transition-colors block"
          >
            info@eco-dream.eu
          </a>
          <a
            href="tel:+420 601 524 815"
            className="text-green-300 hover:text-green-200 transition-colors block"
          >
            +420 601 524 815
          </a>
        </div>
      </div>

      {/* Privacy and Cookie Policy Integration */}
      <div className="text-center text-sm text-gray-400">
        <div className="mb-2">
          <a
            href="https://www.iubenda.com/privacy-policy/74582188"
            class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Privacy Policy"
          >
            {currentLang.privacy}
          </a>
          &nbsp;|&nbsp;
          <a
            href="https://www.iubenda.com/privacy-policy/74582188/cookie-policy"
            class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
            title="Cookie Policy"
          >
            {currentLang.cookiePolicy}
          </a>
        </div>
        <div>
          © {new Date().getFullYear()} {currentLang.copyright}
        </div>
      </div>
    </div>
  </div>
</footer>

    </div>
  );
};

export default EcoDreamWebsite;
