const translations = {
    cz: {
      home: "Domů",
      about: "O nás",
      services: "Služby",
      mission: "Mise",
      contact: "Kontakt",
      homeTitle: "EcoDream S.R.O",
      homeSubtitle:
        "Optimalizujeme podnikové procesy, podporujeme růst a úspěch firem pomocí inovativních a personalizovaných řešení.",
      contactUs: "Kontaktujte nás",
      whoWeAre: "Kdo jsme",
      whoWeAreDescription:
        "EcoDream je společnost se sídlem v České republice, která již 14 let působí v oblasti obchodního a administrativního poradenství. Byla založena s cílem podporovat podniky při optimalizaci jejich podnikových procesů.",
      experience: "14+ Let zkušeností",
      customSolutions: "Personalizovaná řešení",
      ourServices: "Naše služby",
      financialConsulting: "Finanční poradenství",
      financialConsultingDesc:
        "Analýza a optimalizace firemních finančních procesů.",
      strategicPlanning: "Strategické plánování",
      strategicPlanningDesc: "Vývoj individuálních strategií pro firemní růst.",
      compliance: "Compliance",
      complianceDesc: "Řízení regulatorních a daňových povinností.",
      ourMission: "Naše mise",
      ourMissionDesc:
        "Poskytovat praktická a inovativní řešení, přispívat k dlouhodobému růstu a úspěchu našich klientů, neustále se přizpůsobovat potřebám měnícího se ekonomického prostředí.",
      contactSection: "Kontaktujte nás",
      address: "Zelený pruh 1560/99, Braník, 140 00 Praha 4",
      expertTeam: "Expertní tým",
      expertTeamDesc:
        "Náš tým složený z vysoce kvalifikovaných odborníků poskytuje komplexní poradenství v různých oblastech podnikání.",
      marketAnalysis: "Analýza trhu",
      marketAnalysisDesc:
        "Hloubková analýza tržních trendů a příležitostí pro váš byznys.",
      keyValues: "Naše klíčové hodnoty",
      innovation: "Inovace",
      adaptability: "Flexibilita",
      clientFocus: "Zaměření na klienta",
      serviceDesc: "Inovativní řešení přizpůsobená vašim obchodním potřebám",
      termsOfService: "Podmínky služby",
      privacy: "Soukromí",
      copyright: "EcoDream S.R.O. Všechna práva vyhrazena.",
      cookiePolicy: "Politika souborů cookie"
    },
    en: {
      home: "Home",
      about: "About",
      services: "Services",
      mission: "Mission",
      contact: "Contact",
      homeTitle: "EcoDream S.R.O",
      homeSubtitle:
        "We optimize business processes, supporting growth and success of companies with innovative and personalized solutions.",
      contactUs: "Contact Us",
      whoWeAre: "Who We Are",
      whoWeAreDescription:
        "EcoDream is a company based in the Czech Republic that has been operating for 14 years in the field of commercial and administrative consulting. Founded with the goal of supporting businesses in optimizing their business processes.",
      experience: "14+ Years of Experience",
      customSolutions: "Personalized Solutions",
      ourServices: "Our Services",
      financialConsulting: "Financial Consulting",
      financialConsultingDesc:
        "Analysis and optimization of corporate financial processes.",
      strategicPlanning: "Strategic Planning",
      strategicPlanningDesc:
        "Development of custom strategies for business growth.",
      compliance: "Compliance",
      complianceDesc: "Management of regulatory and tax obligations.",
      ourMission: "Our Mission",
      ourMissionDesc:
        "Provide practical and innovative solutions, contributing to the long-term growth and success of our clients, constantly adapting to the needs of a changing economic environment.",
      contactSection: "Contact Us",
      address: "Zelený pruh 1560/99, Braník, 140 00 Prague 4",
      expertTeam: "Expert Team",
      expertTeamDesc:
        "Our team of highly qualified professionals provides comprehensive consulting across various business domains.",
      marketAnalysis: "Market Analysis",
      marketAnalysisDesc:
        "In-depth analysis of market trends and opportunities for your business.",
      keyValues: "Our Key Values",
      innovation: "Innovation",
      adaptability: "Adaptability",
      clientFocus: "Client Focus",
      serviceDesc: "Innovative Solutions Tailored to Your Business Needs",
      termsOfService: "Terms of Service",
      privacy: "Privacy Policy",
      copyright: "EcoDream S.R.O. All rights reserved.",
      cookiePolicy: "Cookies Policy"
    },
  };

  export default translations;