import React from 'react';
import EcoDreamWebsite from './components/EcoDreamWebsite';
import IubendaScript from './components/IubendaScript';
import './index.css';

function App() {
  return (
    <div>
      <IubendaScript />
      <EcoDreamWebsite />
    </div>
  );
}

export default App;